// /root/money-split/src/components/CreateAutomation/AutomationForm.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Automation, TriggerType, ActionType, AutomationStatus, ExecutionFrequency } from '../../models/automation';
import { createAutomation, updateAutomation } from '../../services/api';

// Import Trigger forms
import BalanceThresholdForm from './ParameterForms/TriggerForms/BalanceThresholdForm';
import BalanceDecreaseForm from './ParameterForms/TriggerForms/BalanceDecreaseForm';
import BalanceIncreaseForm from './ParameterForms/TriggerForms/BalanceIncreaseForm';
import TimeBasedForm from './ParameterForms/TriggerForms/TimeBasedForm';
import SolPriceThresholdForm from './ParameterForms/TriggerForms/SolPriceThresholdForm';
import MaintenanceLowForm from './ParameterForms/TriggerForms/MaintenanceLowForm';

// Import Action forms
import DistributeForm from './ParameterForms/ActionForms/DistributeForm';
import NotificationForm from './ParameterForms/ActionForms/NotificationForm';
import DoNothingForm from './ParameterForms/ActionForms/DoNothingForm';

// Import selectors
import TriggerSelector from './TriggerSelector';
import ActionSelector from './ActionSelector';

interface AutomationFormProps {
  existingAutomation?: Automation;
  isEdit?: boolean;
}

const AutomationForm: React.FC<AutomationFormProps> = ({ existingAutomation, isEdit = false }) => {
  const navigate = useNavigate();
  
  // Form state
  const [selectedTrigger, setSelectedTrigger] = useState<TriggerType | null>(
    existingAutomation?.trigger?.trigger_type || null
  );
  const [selectedAction, setSelectedAction] = useState<ActionType | null>(
    existingAutomation?.action?.action_type || null
  );
  const [triggerParams, setTriggerParams] = useState<any>(
    existingAutomation?.trigger?.params || {}
  );
  const [actionParams, setActionParams] = useState<any>(
    existingAutomation?.action?.params || {}
  );
  const [name, setName] = useState(existingAutomation?.name || '');
  const [description, setDescription] = useState(existingAutomation?.description || '');
  const [status, setStatus] = useState<AutomationStatus>(
    existingAutomation?.status || 'active' as AutomationStatus
  );
  const [executionFrequency, setExecutionFrequency] = useState<ExecutionFrequency>(
    existingAutomation?.execution_frequency || 'once' as ExecutionFrequency
  );
  
  // UI state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState<any>(null);

  // Update form data preview whenever any field changes
  useEffect(() => {
    if (selectedTrigger) {
      setFormData({
        id: existingAutomation?.id, // Include ID if editing
        trigger: {
          trigger_type: selectedTrigger,
          params: triggerParams
        },
        action: {
          action_type: selectedAction || null,
          params: actionParams
        },
        name: name,
        description: description,
        status: status,
        execution_frequency: executionFrequency
      });
    }
  }, [selectedTrigger, triggerParams, selectedAction, actionParams, name, description, status, executionFrequency, existingAutomation?.id]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Form submitted");
    
    if (!selectedTrigger || !selectedAction || !name) {
      setError('Please complete all required fields');
      return;
    }
    
    try {
      setIsSubmitting(true);
      console.log("Submitting form data:", formData);
      
      if (isEdit && existingAutomation?.id) {
        // Update existing automation
        const result = await updateAutomation(existingAutomation.id, formData);
        if (result.success) {
          navigate('/automations');
        } else {
          setError(result.error || 'Failed to update automation');
        }
      } else {
        // Create new automation
        const result = await createAutomation(formData);
        if (result.success) {
          navigate('/automations');
        } else {
          setError(result.error || 'Failed to create automation');
        }
      }
    } catch (err: any) {
      setError('An error occurred: ' + (err.message || ''));
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTriggerSelect = (trigger: TriggerType) => {
    setSelectedTrigger(trigger);
    setTriggerParams({});
  };
  
  const handleActionSelect = (action: ActionType) => {
    setSelectedAction(action);
    setActionParams({});
  };

  // Render the appropriate trigger form based on selection
  const renderTriggerForm = () => {
    switch (selectedTrigger) {
      case 'balance_threshold':
        return <BalanceThresholdForm onChange={setTriggerParams} initialParams={triggerParams} />;
      case 'balance_decrease':
        return <BalanceDecreaseForm onChange={setTriggerParams} initialParams={triggerParams} />;
      case 'balance_increase':
        return <BalanceIncreaseForm onChange={setTriggerParams} initialParams={triggerParams} />;
      case 'time_trigger':
        return <TimeBasedForm onChange={setTriggerParams} initialParams={triggerParams} />;
      case 'sol_price_threshold':
        return <SolPriceThresholdForm onChange={setTriggerParams} initialParams={triggerParams} />;
      case 'maintenance_low':
        return <MaintenanceLowForm onChange={setTriggerParams} initialParams={triggerParams} />;
      default:
        return <p>Select a trigger type</p>;
    }
  };

  // Render the appropriate action form based on selection
  const renderActionForm = () => {
    switch (selectedAction) {
      case 'distribution':
        return <DistributeForm onChange={setActionParams} initialParams={actionParams} />;
      case 'notification':
        return <NotificationForm onChange={setActionParams} initialParams={actionParams} />;
      case 'nothing':
        return <DoNothingForm onChange={setActionParams} initialParams={actionParams} />;
      default:
        return <p>Select an action type</p>;
    }
  };

  return (
    <div className="content">
      <div className="content-header">
        <h1 className="content-title">
          {isEdit ? 'Edit Automation' : 'Create New Automation'}
        </h1>
      </div>
      
      <div className="content-body">
        {error && <div className="error-container">{error}</div>}
        
        <form onSubmit={handleSubmit}>
          <div className="automation-form-section">
            <h2 className="creation-step">
              <span className="step-number">1</span>
              Select Trigger Condition
            </h2>
            <TriggerSelector 
              selectedTrigger={selectedTrigger} 
              onSelectTrigger={handleTriggerSelect} 
            />
            
            {selectedTrigger && (
              <div className="params-section">
                <h3 className="params-title">
                  Configure {selectedTrigger.replace(/_/g, ' ')} Parameters
                </h3>
                {renderTriggerForm()}
              </div>
            )}
          </div>
          
          <div className="automation-form-section">
            <h2 className="creation-step">
              <span className="step-number">2</span>
              Select Action to Perform
            </h2>
            <ActionSelector
              selectedAction={selectedAction}
              onSelectAction={handleActionSelect}
            />
            
            {selectedAction && (
              <div className="params-section">
                <h3 className="params-title">
                  Configure {selectedAction.replace(/_/g, ' ')} Parameters
                </h3>
                {renderActionForm()}
              </div>
            )}
          </div>
          
          <div className="automation-form-section">
            <h2 className="creation-step">
              <span className="step-number">3</span>
              Automation Details
            </h2>
            <div className="params-section">
              <div className="form-group">
                <label className="form-label" htmlFor="name">Name</label>
                <input
                  id="name"
                  type="text"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  className="form-control"
                  placeholder="Give your automation a name"
                  required
                />
              </div>
              
              <div className="form-group">
                <label className="form-label" htmlFor="description">Description (Optional)</label>
                <textarea
                  id="description"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  className="form-control"
                  placeholder="What does this automation do?"
                  rows={3}
                />
              </div>
              
              <div className="form-row">
                <div className="form-col">
                  <label className="form-label" htmlFor="status">Status</label>
                  <select
                    id="status"
                    value={status}
                    onChange={e => setStatus(e.target.value as AutomationStatus)}
                    className="form-control"
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                
                <div className="form-col">
                  <label className="form-label" htmlFor="executionFrequency">Execution Frequency</label>
                  <select
                    id="executionFrequency"
                    value={executionFrequency}
                    onChange={e => setExecutionFrequency(e.target.value as ExecutionFrequency)}
                    className="form-control"
                  >
                    <option value="once">Execute Once</option>
                    <option value="permanent">Execute Every Time</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          
          <div className="automation-form-section">
            <h2 className="creation-step">
              <span className="step-number">4</span>
              Review
            </h2>
            <div className="code-preview">
              <pre>{JSON.stringify(formData, null, 2)}</pre>
            </div>
          </div>
          
          {/* Form Actions - Submit and Cancel Buttons */}
          <div className="form-actions">
            <button 
              type="button" 
              onClick={() => navigate('/automations')}
              className="btn btn-secondary"
            >
              Cancel
            </button>
            
            <button 
              type="submit"
              className="btn btn-primary btn-lg"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Processing...' : isEdit ? 'Update Automation' : 'Create Automation'}
            </button>
          </div>
          
          {/* Yellow button container - REMOVED */}
        </form>
      </div>
    </div>
  );
};

export default AutomationForm;