// components/CreateAutomation/TriggerSelector.tsx
import React from 'react';
import { TriggerType } from '../../models/automation';

interface TriggerSelectorProps {
  selectedTrigger: TriggerType | null;
  onSelectTrigger: (trigger: TriggerType) => void;
}

const TriggerSelector: React.FC<TriggerSelectorProps> = ({ selectedTrigger, onSelectTrigger }) => {
  const triggers = [
    {
      id: 'balance_threshold' as TriggerType,
      name: 'Balance Threshold',
      description: 'Trigger when an account balance crosses a threshold',
      icon: '💰'
    },
    {
      id: 'balance_increase' as TriggerType,
      name: 'Balance Increase',
      description: 'Trigger when an account balance increases by an amount',
      icon: '📈'
    },
    {
      id: 'balance_decrease' as TriggerType,
      name: 'Balance Decrease',
      description: 'Trigger when an account balance decreases by an amount',
      icon: '📉'
    },
    {
      id: 'time_trigger' as TriggerType,
      name: 'Time-based',
      description: 'Trigger at a scheduled time',
      icon: '⏰'
    },
    {
      id: 'sol_price_threshold' as TriggerType,
      name: 'SOL Price',
      description: 'Trigger when SOL price crosses a threshold',
      icon: '💲'
    },
    {
      id: 'maintenance_low' as TriggerType,
      name: 'Low Balance Warning',
      description: 'Trigger when maintenance account is low',
      icon: '⚠️'
    }
  ];

  return (
    <div className="trigger-grid">
      {triggers.map((trigger) => (
        <div
          key={trigger.id}
          className={`trigger-card ${selectedTrigger === trigger.id ? 'selected' : ''}`}
          onClick={() => onSelectTrigger(trigger.id)}
        >
          <div className="trigger-icon">{trigger.icon}</div>
          <div className="trigger-name">{trigger.name}</div>
          <div className="trigger-desc">{trigger.description}</div>
        </div>
      ))}
    </div>
  );
};

export default TriggerSelector;