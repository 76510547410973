// src/components/AutomationList/AutomationList.tsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AutomationCard from './AutomationCard';
import AutomationEngineStatus from './AutomationEngineStatus';
import { 
  fetchAutomations, 
  deleteAutomation, 
  toggleAutomationStatus, 
  evaluateAutomations,
  triggerAutomation
} from '../../services/api';

const AutomationList: React.FC = () => {
  const [automations, setAutomations] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [evaluating, setEvaluating] = useState<boolean>(false);

  // Fetch automations when component mounts
  useEffect(() => {
    fetchData();
  }, []);

  // Function to fetch automations from API
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetchAutomations();
      console.log("API response:", response); // Log the response to debug
      
      if (response.success) {
        // Check different possible formats of the response
        if (Array.isArray(response.data)) {
          // If response.data is directly an array
          setAutomations(response.data);
        } else if (response.data && Array.isArray(response.data.automations)) {
          // If automations are nested in response.data.automations
          setAutomations(response.data.automations);
        } else if (response.data && typeof response.data === 'object') {
          // If response.data is an object but not an array
          // This could happen if API returns a single automation or a different structure
          console.warn("Unexpected response format:", response.data);
          setAutomations([]); // Set to empty array as fallback
        } else {
          // Fallback to empty array
          console.error("Invalid response data format:", response.data);
          setAutomations([]);
        }
        setError(null);
      } else {
        setError(response.error || 'Failed to load automations');
        setAutomations([]);
      }
    } catch (err) {
      console.error('Error fetching automations:', err);
      setError('Failed to load automations');
      setAutomations([]);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle automation deletion
  const handleDelete = async (id: string) => {
    // Confirm deletion
    if (!window.confirm('Are you sure you want to delete this automation?')) {
      return;
    }

    try {
      const result = await deleteAutomation(id);
      
      if (result.success) {
        // Remove the automation from the list
        setAutomations(automations.filter(automation => automation.id !== id));
      } else {
        setError(result.error || 'Failed to delete automation');
      }
    } catch (err) {
      console.error('Error deleting automation:', err);
      setError('Failed to delete automation');
    }
  };

  // Function to toggle automation status
  const handleToggleStatus = async (id: string, newStatus: string) => {
    try {
      const result = await toggleAutomationStatus(id, newStatus);
      
      if (result.success) {
        // Update the automation status in the list
        setAutomations(automations.map(automation => 
          automation.id === id ? { ...automation, status: newStatus } : automation
        ));
      } else {
        setError(result.error || `Failed to ${newStatus === 'active' ? 'activate' : 'deactivate'} automation`);
      }
    } catch (err) {
      console.error('Error toggling automation status:', err);
      setError(`Failed to ${newStatus === 'active' ? 'activate' : 'deactivate'} automation`);
    }
  };

  // Function to handle manually triggering an automation
  const handleTriggerAutomation = async (id: string) => {
    try {
      const result = await triggerAutomation(id);
      
      if (result.success) {
        alert(`Automation triggered successfully`);
        // Refresh the list to show updated execution status
        fetchData();
      } else {
        setError(result.error || 'Failed to trigger automation');
      }
    } catch (err) {
      console.error('Error triggering automation:', err);
      setError('Failed to trigger automation');
    }
  };

  // Function to evaluate all automations
  const handleEvaluateAll = async () => {
    setEvaluating(true);
    try {
      const result = await evaluateAutomations();
      
      if (result.success) {
        // Get the data from the result
        const data = result.data || {};
        
        // Show a success message
        alert(`Evaluated ${data.evaluated_count || 0} automations. 
               ${data.triggered_count || 0} triggered, 
               ${data.action_success_count || 0} actions succeeded.`);
        
        // Refresh the automations list to show updated status
        fetchData();
      } else {
        setError(result.error || 'Failed to evaluate automations');
      }
    } catch (err) {
      console.error('Error evaluating automations:', err);
      setError('Failed to evaluate automations');
    } finally {
      setEvaluating(false);
    }
  };

  return (
    <div>
      <div className="page-header">
        <h1>Automations</h1>
        <div style={{ display: 'flex', gap: '10px' }}>
          <button 
            className="btn-secondary" 
            onClick={handleEvaluateAll}
            disabled={evaluating}
          >
            {evaluating ? 'Evaluating...' : 'Evaluate All'}
          </button>
          <Link to="/create-automation" className="create-btn">
            <i className="fas fa-plus"></i> Create Automation
          </Link>
        </div>
      </div>

      {/* Display the automation engine status */}
      <AutomationEngineStatus />

      {/* Display errors if any */}
      {error && (
        <div className="error-state">
          <p>{error}</p>
          <button 
            className="btn-secondary" 
            onClick={fetchData}
            style={{ marginTop: '10px' }}
          >
            Try Again
          </button>
        </div>
      )}

      {/* Loading state */}
      {loading ? (
        <div className="loading-state">Loading automations...</div>
      ) : (
        <div>
          {/* Empty state */}
          {!loading && automations.length === 0 && !error ? (
            <div className="empty-state">
              <div>
                <i className="fas fa-robot" style={{ fontSize: '24px', marginBottom: '10px' }}></i>
              </div>
              <h3>No automations yet</h3>
              <p>Create your first automation to automate fund distribution.</p>
              <Link to="/create-automation" className="btn-primary">
                Create Automation
              </Link>
            </div>
          ) : (
            /* Automation list */
            <div className="automation-list">
              {Array.isArray(automations) ? automations.map(automation => (
                <AutomationCard
                  key={automation.id}
                  automation={automation}
                  onDelete={handleDelete}
                  onToggleStatus={handleToggleStatus}
                  onTrigger={handleTriggerAutomation}
                />
              )) : (
                <div className="error-state">
                  <p>Error: Expected automations to be an array but got {typeof automations}</p>
                  <button 
                    className="btn-secondary" 
                    onClick={fetchData}
                    style={{ marginTop: '10px' }}
                  >
                    Try Again
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AutomationList;