// /root/money-split/src/components/CreateAutomation/ParameterForms/TriggerForms/MaintenanceLowForm.tsx
import React, { useState, useEffect } from 'react';

interface MaintenanceLowFormProps {
  onChange: (params: any) => void;
  initialParams?: any;
}

const MaintenanceLowForm: React.FC<MaintenanceLowFormProps> = ({ 
  onChange, 
  initialParams = {} 
}) => {
  const [threshold, setThreshold] = useState(initialParams.threshold || 0.05);
  const [unit, setUnit] = useState(initialParams.unit || 'sol');
  
  useEffect(() => {
    onChange({
      threshold: parseFloat(threshold),
      unit
    });
  }, [threshold, unit, onChange]);

  return (
    <div className="form-container">
      <div className="form-group">
        <label htmlFor="threshold">Low Balance Threshold</label>
        <input 
          id="threshold" 
          type="number" 
          value={threshold} 
          onChange={e => setThreshold(e.target.value)}
          className="form-control"
          min="0"
          step="0.01"
        />
      </div>

      <div className="form-group">
        <label htmlFor="unit">Unit</label>
        <select 
          id="unit" 
          value={unit} 
          onChange={e => setUnit(e.target.value)}
          className="form-control"
        >
          <option value="sol">SOL</option>
          <option value="usd">USD</option>
        </select>
      </div>
    </div>
  );
};

export default MaintenanceLowForm;