// /root/money-split/src/components/CreateAutomation/ActionSelector.tsx
import React from 'react';
import { ActionType } from '../../models/automation';

interface ActionSelectorProps {
  selectedAction: ActionType | null;
  onSelectAction: (action: ActionType) => void;
}

const ActionSelector: React.FC<ActionSelectorProps> = ({ selectedAction, onSelectAction }) => {
  const actions = [
    {
      id: 'distribution' as ActionType,
      name: 'Distribute Funds',
      description: 'Split funds between recipients',
      icon: '👥'
    },
    {
      id: 'notification' as ActionType,
      name: 'Send Notification',
      description: 'Send a notification or alert',
      icon: '📱'
    },
    {
      id: 'nothing' as ActionType,
      name: 'Do Nothing',
      description: 'Use for testing or monitoring only',
      icon: '⏸️'
    }
  ];

  return (
    <div className="action-grid">
      {actions.map((action) => (
        <div
          key={action.id}
          className={`action-card ${selectedAction === action.id ? 'selected' : ''} ${action.id.replace(/_/g, '-')}`}
          onClick={() => onSelectAction(action.id)}
        >
          <div className="action-icon">{action.icon}</div>
          <div className="action-name">{action.name}</div>
          <div className="action-desc">{action.description}</div>
        </div>
      ))}
    </div>
  );
};

export default ActionSelector;