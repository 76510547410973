// /root/money-split/src/components/CreateAutomation/ParameterForms/TriggerForms/BalanceIncreaseForm.tsx
import React, { useState, useEffect } from 'react';

interface BalanceIncreaseFormProps {
  onChange: (params: any) => void;
  initialParams?: any;
}

const BalanceIncreaseForm: React.FC<BalanceIncreaseFormProps> = ({ 
  onChange, 
  initialParams = {} 
}) => {
  const [account, setAccount] = useState(initialParams.account || 'vault');
  const [increaseAmount, setIncreaseAmount] = useState(initialParams.increase_amount || 5);
  const [increaseUnit, setIncreaseUnit] = useState(initialParams.increase_unit || 'sol');
  const [timeWindow, setTimeWindow] = useState(initialParams.time_window || 60);
  
  useEffect(() => {
    onChange({
      account,
      increase_amount: parseFloat(increaseAmount),
      increase_unit: increaseUnit,
      time_window: parseInt(timeWindow)
    });
  }, [account, increaseAmount, increaseUnit, timeWindow, onChange]);

  return (
    <div className="form-container">
      <div className="form-group">
        <label htmlFor="account">Account</label>
        <select 
          id="account" 
          value={account} 
          onChange={e => setAccount(e.target.value)}
          className="form-control"
        >
          <option value="vault">Vault</option>
          <option value="recipient1">Recipient 1</option>
          <option value="recipient2">Recipient 2</option>
          <option value="maintenance">Maintenance</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="increaseAmount">Increase Amount</label>
        <input 
          id="increaseAmount" 
          type="number" 
          value={increaseAmount} 
          onChange={e => setIncreaseAmount(e.target.value)}
          className="form-control"
          min="0"
          step="0.01"
        />
      </div>

      <div className="form-group">
        <label htmlFor="increaseUnit">Unit</label>
        <select 
          id="increaseUnit" 
          value={increaseUnit} 
          onChange={e => setIncreaseUnit(e.target.value)}
          className="form-control"
        >
          <option value="sol">SOL</option>
          <option value="usd">USD</option>
          <option value="percent">Percent</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="timeWindow">Time Window (minutes)</label>
        <input 
          id="timeWindow" 
          type="number" 
          value={timeWindow} 
          onChange={e => setTimeWindow(e.target.value)}
          className="form-control"
          min="1"
        />
      </div>
    </div>
  );
};

export default BalanceIncreaseForm;