// /root/money-split/src/components/CreateAutomation/ParameterForms/ActionForms/DoNothingForm.tsx
import React, { useEffect } from 'react';

interface DoNothingFormProps {
  onChange: (params: any) => void;
  initialParams?: any;
}

const DoNothingForm: React.FC<DoNothingFormProps> = ({ 
  onChange, 
  initialParams = {} 
}) => {
  // This form doesn't need parameters since it's a "do nothing" action,
  // but we still need to call onChange with an empty object
  useEffect(() => {
    onChange({});
  }, [onChange]);

  return (
    <div className="form-container">
      <p>This action doesn't require any parameters.</p>
      <p>It can be used for testing trigger conditions without performing any actions.</p>
    </div>
  );
};

export default DoNothingForm;