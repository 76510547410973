// /root/money-split/src/components/CreateAutomation/ParameterForms/TriggerForms/BalanceDecreaseForm.tsx
import React, { useState, useEffect } from 'react';

interface BalanceDecreaseFormProps {
  onChange: (params: any) => void;
  initialParams?: any;
}

const BalanceDecreaseForm: React.FC<BalanceDecreaseFormProps> = ({ 
  onChange, 
  initialParams = {} 
}) => {
  const [account, setAccount] = useState(initialParams.account || 'vault');
  const [decreaseAmount, setDecreaseAmount] = useState(initialParams.decrease_amount || 5);
  const [decreaseUnit, setDecreaseUnit] = useState(initialParams.decrease_unit || 'sol');
  const [timeWindow, setTimeWindow] = useState(initialParams.time_window || 60);
  
  useEffect(() => {
    onChange({
      account,
      decrease_amount: parseFloat(decreaseAmount),
      decrease_unit: decreaseUnit,
      time_window: parseInt(timeWindow)
    });
  }, [account, decreaseAmount, decreaseUnit, timeWindow, onChange]);

  return (
    <div className="form-container">
      <div className="form-group">
        <label htmlFor="account">Account</label>
        <select 
          id="account" 
          value={account} 
          onChange={e => setAccount(e.target.value)}
          className="form-control"
        >
          <option value="vault">Vault</option>
          <option value="recipient1">Recipient 1</option>
          <option value="recipient2">Recipient 2</option>
          <option value="maintenance">Maintenance</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="decreaseAmount">Decrease Amount</label>
        <input 
          id="decreaseAmount" 
          type="number" 
          value={decreaseAmount} 
          onChange={e => setDecreaseAmount(e.target.value)}
          className="form-control"
          min="0"
          step="0.01"
        />
      </div>

      <div className="form-group">
        <label htmlFor="decreaseUnit">Unit</label>
        <select 
          id="decreaseUnit" 
          value={decreaseUnit} 
          onChange={e => setDecreaseUnit(e.target.value)}
          className="form-control"
        >
          <option value="sol">SOL</option>
          <option value="usd">USD</option>
          <option value="percent">Percent</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="timeWindow">Time Window (minutes)</label>
        <input 
          id="timeWindow" 
          type="number" 
          value={timeWindow} 
          onChange={e => setTimeWindow(e.target.value)}
          className="form-control"
          min="1"
        />
      </div>
    </div>
  );
};

export default BalanceDecreaseForm;