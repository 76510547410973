// src/components/Settings/Settings.tsx
import React, { useState, useEffect } from 'react';
import { getBalances } from '../../services/api';

const Settings: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [balances, setBalances] = useState<any>(null);
  const [timezone, setTimezone] = useState<string>(
    localStorage.getItem('timezone') || Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  useEffect(() => {
    const fetchBalances = async () => {
      try {
        setLoading(true);
        const result = await getBalances();
        if (result.success) {
          setBalances(result.data);
        } else {
          setError(result.error || 'Failed to load balances');
        }
      } catch (err) {
        console.error('Error fetching balances:', err);
        setError('Failed to load balances');
      } finally {
        setLoading(false);
      }
    };

    fetchBalances();
  }, []);

  const handleTimezoneChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newTimezone = e.target.value;
    setTimezone(newTimezone);
    localStorage.setItem('timezone', newTimezone);
  };

  if (loading) return <div className="loading-state">Loading settings...</div>;
  if (error) return <div className="error-state">{error}</div>;
  if (!balances) return <div className="empty-state">No data available</div>;

  return (
    <div className="settings-container">
      <div className="page-header">
        <h1>Account Settings</h1>
      </div>
      
      <div className="settings-card">
        <h2>Wallet Addresses</h2>
        <div className="settings-item">
          <div className="settings-label">Vault Address</div>
          <div className="settings-value address">
            {balances.data?.accounts?.vault?.address || 'Not available'}
          </div>
        </div>
        <div className="settings-item">
          <div className="settings-label">Maintenance Address</div>
          <div className="settings-value address">
            {balances.data?.accounts?.maintenance?.address || 'Not available'}
          </div>
        </div>
        <div className="settings-item">
          <div className="settings-label">Recipient 1 Address</div>
          <div className="settings-value address">
            {balances.data?.accounts?.recipient1?.address || 'Not available'}
          </div>
        </div>
        <div className="settings-item">
          <div className="settings-label">Recipient 2 Address</div>
          <div className="settings-value address">
            {balances.data?.accounts?.recipient2?.address || 'Not available'}
          </div>
        </div>
      </div>
      
      <div className="settings-card">
        <h2>Display Preferences</h2>
        <div className="settings-item">
          <label className="settings-label" htmlFor="timezone">Timezone</label>
          <select 
            id="timezone" 
            className="form-control" 
            value={timezone} 
            onChange={handleTimezoneChange}
          >
            <option value="UTC">UTC (Coordinated Universal Time)</option>
            
            {/* Americas */}
            <option value="America/New_York">New York (UTC-5/4)</option>
            <option value="America/Chicago">Chicago (UTC-6/5)</option>
            <option value="America/Denver">Denver (UTC-7/6)</option>
            <option value="America/Los_Angeles">Los Angeles (UTC-8/7)</option>
            <option value="America/Anchorage">Anchorage (UTC-9/8)</option>
            <option value="America/Adak">Adak, Hawaii (UTC-10/9)</option>
            <option value="Pacific/Honolulu">Honolulu (UTC-10)</option>
            <option value="America/Toronto">Toronto (UTC-5/4)</option>
            <option value="America/Vancouver">Vancouver (UTC-8/7)</option>
            <option value="America/Mexico_City">Mexico City (UTC-6/5)</option>
            <option value="America/Bogota">Bogota (UTC-5)</option>
            <option value="America/Lima">Lima (UTC-5)</option>
            <option value="America/Santiago">Santiago (UTC-4/3)</option>
            <option value="America/Sao_Paulo">São Paulo (UTC-3/2)</option>
            <option value="America/Buenos_Aires">Buenos Aires (UTC-3)</option>
            
            {/* Europe */}
            <option value="Europe/London">London (UTC+0/1)</option>
            <option value="Europe/Dublin">Dublin (UTC+0/1)</option>
            <option value="Europe/Lisbon">Lisbon (UTC+0/1)</option>
            <option value="Europe/Paris">Paris (UTC+1/2)</option>
            <option value="Europe/Berlin">Berlin (UTC+1/2)</option>
            <option value="Europe/Rome">Rome (UTC+1/2)</option>
            <option value="Europe/Madrid">Madrid (UTC+1/2)</option>
            <option value="Europe/Amsterdam">Amsterdam (UTC+1/2)</option>
            <option value="Europe/Zurich">Zurich (UTC+1/2)</option>
            <option value="Europe/Athens">Athens (UTC+2/3)</option>
            <option value="Europe/Helsinki">Helsinki (UTC+2/3)</option>
            <option value="Europe/Kyiv">Kyiv (UTC+2/3)</option>
            <option value="Europe/Istanbul">Istanbul (UTC+3)</option>
            <option value="Europe/Moscow">Moscow (UTC+3)</option>
            
            {/* Asia */}
            <option value="Asia/Dubai">Dubai (UTC+4)</option>
            <option value="Asia/Karachi">Karachi (UTC+5)</option>
            <option value="Asia/Kolkata">Mumbai/New Delhi (UTC+5:30)</option>
            <option value="Asia/Dhaka">Dhaka (UTC+6)</option>
            <option value="Asia/Bangkok">Bangkok (UTC+7)</option>
            <option value="Asia/Jakarta">Jakarta (UTC+7)</option>
            <option value="Asia/Singapore">Singapore (UTC+8)</option>
            <option value="Asia/Kuala_Lumpur">Kuala Lumpur (UTC+8)</option>
            <option value="Asia/Shanghai">Shanghai (UTC+8)</option>
            <option value="Asia/Hong_Kong">Hong Kong (UTC+8)</option>
            <option value="Asia/Taipei">Taipei (UTC+8)</option>
            <option value="Asia/Seoul">Seoul (UTC+9)</option>
            <option value="Asia/Tokyo">Tokyo (UTC+9)</option>
            
            {/* Oceania */}
            <option value="Australia/Perth">Perth (UTC+8)</option>
            <option value="Australia/Adelaide">Adelaide (UTC+9:30/10:30)</option>
            <option value="Australia/Brisbane">Brisbane (UTC+10)</option>
            <option value="Australia/Sydney">Sydney (UTC+10/11)</option>
            <option value="Australia/Melbourne">Melbourne (UTC+10/11)</option>
            <option value="Pacific/Auckland">Auckland (UTC+12/13)</option>
            
            {/* Africa */}
            <option value="Africa/Cairo">Cairo (UTC+2)</option>
            <option value="Africa/Nairobi">Nairobi (UTC+3)</option>
            <option value="Africa/Lagos">Lagos (UTC+1)</option>
            <option value="Africa/Johannesburg">Johannesburg (UTC+2)</option>
            <option value="Atlantic/Cape_Verde">Cape Verde (UTC-1)</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Settings;