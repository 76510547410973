import React from 'react';
import { BrowserRouter, Routes, Route, NavLink, Navigate } from 'react-router-dom';
import './App.css'; // Single centralized CSS file
import AutomationList from './components/AutomationList/AutomationList';
import AutomationForm from './components/CreateAutomation/AutomationForm';
import Settings from './components/Settings/Settings';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <div className="app-container">
        <header className="app-header">
          <div className="container">
            <div className="header-content">
              <div className="logo">
                <span className="logo-icon">💸</span>
                <h1>Money Split</h1>
              </div>
              
              <nav className="main-nav">
                <NavLink 
                  to="/automations" 
                  className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
                >
                  Automations
                </NavLink>
                <NavLink 
                  to="/create-automation" 
                  className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
                >
                  Create Automation
                </NavLink>
                {/* Add this new NavLink for Settings */}
                <NavLink 
                  to="/settings" 
                  className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
                >
                  Settings
                </NavLink>
              </nav>
            </div>
          </div>
        </header>
        
        <main className="main-content">
          <div className="container">
            <Routes>
              <Route path="/automations" element={<AutomationList />} />
              <Route path="/create-automation" element={<AutomationForm />} />
              {/* Add this new Route for Settings */}
              <Route path="/settings" element={<Settings />} />
              <Route path="/" element={<Navigate to="/automations" replace />} />
            </Routes>
          </div>
        </main>
      </div>
    </BrowserRouter>
  );
};

export default App;