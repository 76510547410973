// /root/money-split/src/services/api.ts
const API_BASE_URL = '/api';


// Generic request handler with error handling
async function fetchWithErrorHandling(url: string, options?: RequestInit) {
  try {
    const response = await fetch(url, options);
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      return { 
        success: false, 
        error: errorData.message || `API error: ${response.status}` 
      };
    }
    
    const result = await response.json();
    return { success: true, data: result };
  } catch (error) {
    console.error('API request failed:', error);
    return { success: false, error: 'Network or server error occurred' };
  }
}

// =============== Automation API Functions ===============

/**
 * Get all automations
 */
export async function getAutomations() {
  return fetchWithErrorHandling(`${API_BASE_URL}/automations/list`);
}

/**
 * Fetch automations list directly from API
 */
export async function fetchAutomationsList() {
  try {
    const response = await fetch(`${API_BASE_URL}/automations/list`);
    return await response.json();
  } catch (error) {
    console.error('API request failed:', error);
    return { success: false, error: 'Network or server error occurred' };
  }
}

/**
 * Create a new automation
 * @param data The automation data to create
 */
export async function createAutomation(data: any) {
  return fetchWithErrorHandling(`${API_BASE_URL}/automations/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  });
}

/**
 * Update an existing automation
 * @param id The ID of the automation to update
 * @param data The updated automation data
 */
export async function updateAutomation(id: string, data: any) {
  return fetchWithErrorHandling(`${API_BASE_URL}/automations/update/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  });
}

/**
 * Delete an automation
 * @param id The ID of the automation to delete
 */
export async function deleteAutomation(id: string) {
  return fetchWithErrorHandling(`${API_BASE_URL}/automations/delete/${id}`, {
    method: 'DELETE'
  });
}

/**
 * Manually trigger an automation
 * @param id The ID of the automation to trigger
 */
export async function triggerAutomation(id: string) {
  return fetchWithErrorHandling(`${API_BASE_URL}/automations/trigger/${id}`, {
    method: 'POST'
  });
}

/**
 * Toggle an automation's status (active/inactive)
 * @param id The ID of the automation to update
 * @param status The new status ('active' or 'inactive')
 */
export async function toggleAutomationStatus(id: string, status: string) {
  return fetchWithErrorHandling(`${API_BASE_URL}/automations/${id}/status`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ status })
  });
}

// =============== Balance API Functions ===============

/**
 * Get current account balances
 */
export async function getBalances() {
  return fetchWithErrorHandling(`${API_BASE_URL}/balance`);
}

/**
 * Preview a split with given percentages
 */
export async function previewSplit(params: any) {
  return fetchWithErrorHandling(`${API_BASE_URL}/preview-split`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params)
  });
}

/**
 * Execute a split with given percentages
 */
export async function executeSplit(params: any) {
  return fetchWithErrorHandling(`${API_BASE_URL}/split`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params)
  });
}

/**
 * Get system status
 */
export async function getStatus() {
  return fetchWithErrorHandling(`${API_BASE_URL}/status`);
}

// For components that import fetchAutomations, redirect to getAutomations
export const fetchAutomations = getAutomations;

// Create variable for default export to fix ESLint warning
const apiService = {
  // Automation functions
  getAutomations,
  fetchAutomationsList,
  createAutomation,
  updateAutomation,
  deleteAutomation,
  triggerAutomation,
  toggleAutomationStatus,
  
  // Balance and transaction functions
  getBalances,
  previewSplit,
  executeSplit,
  
  // System functions
  getStatus
};

// Add these to your api.ts file

/**
 * Manually trigger evaluation of all automations
 */
export async function evaluateAutomations() {
  return fetchWithErrorHandling(`${API_BASE_URL}/automations/evaluate`, {
    method: 'POST'
  });
}

/**
 * Trigger a specific automation by ID
 * @param id The ID of the automation to trigger
 */
export async function triggerSpecificAutomation(id: string) {
  return fetchWithErrorHandling(`${API_BASE_URL}/automations/trigger/${id}`, {
    method: 'POST'
  });
}

/**
 * Get automation execution status
 */
export async function getAutomationStatus() {
  return fetchWithErrorHandling(`${API_BASE_URL}/automations/status`);
}

export default apiService;