// /root/money-split/src/components/CreateAutomation/ParameterForms/TriggerForms/BalanceThresholdForm.tsx
import React, { useState, useEffect } from 'react';

interface BalanceThresholdFormProps {
  onChange: (params: any) => void;
  initialParams?: any;
}

const BalanceThresholdForm: React.FC<BalanceThresholdFormProps> = ({ 
  onChange, 
  initialParams = {} 
}) => {
  const [account, setAccount] = useState(initialParams.account || 'vault');
  const [comparison, setComparison] = useState(initialParams.comparison || 'greater_than');
  const [threshold, setThreshold] = useState(initialParams.threshold || 10);
  const [unit, setUnit] = useState(initialParams.unit || 'sol');

  useEffect(() => {
    onChange({
      account,
      comparison,
      threshold: parseFloat(threshold),
      unit
    });
  }, [account, comparison, threshold, unit, onChange]);

  return (
    <div className="form-container">
      <div className="form-group">
        <label htmlFor="account">Account</label>
        <select 
          id="account" 
          value={account} 
          onChange={e => setAccount(e.target.value)}
          className="form-control"
        >
          <option value="vault">Vault</option>
          <option value="recipient1">Recipient 1</option>
          <option value="recipient2">Recipient 2</option>
          <option value="maintenance">Maintenance</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="comparison">Comparison</label>
        <select 
          id="comparison" 
          value={comparison} 
          onChange={e => setComparison(e.target.value)}
          className="form-control"
        >
          <option value="greater_than">Greater than</option>
          <option value="less_than">Less than</option>
          <option value="equal_to">Equal to</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="threshold">Threshold</label>
        <input 
          id="threshold" 
          type="number" 
          value={threshold} 
          onChange={e => setThreshold(e.target.value)}
          className="form-control"
          min="0"
          step="0.01"
        />
      </div>

      <div className="form-group">
        <label htmlFor="unit">Unit</label>
        <select 
          id="unit" 
          value={unit} 
          onChange={e => setUnit(e.target.value)}
          className="form-control"
        >
          <option value="sol">SOL</option>
          <option value="usd">USD</option>
        </select>
      </div>
    </div>
  );
};

export default BalanceThresholdForm;