// /root/money-split/src/components/CreateAutomation/ParameterForms/ActionForms/NotificationForm.tsx
import React, { useState, useEffect } from 'react';

interface NotificationFormProps {
  onChange: (params: any) => void;
  initialParams?: any;
}

const NotificationForm: React.FC<NotificationFormProps> = ({ 
  onChange, 
  initialParams = {} 
}) => {
  const [method, setMethod] = useState(initialParams.method || 'email');
  const [recipient, setRecipient] = useState(initialParams.recipient || '');
  const [message, setMessage] = useState(initialParams.message || '');
  const [includeBalances, setIncludeBalances] = useState(initialParams.include_balances ?? true);
  
  useEffect(() => {
    onChange({
      method,
      recipient,
      message,
      include_balances: includeBalances
    });
  }, [method, recipient, message, includeBalances, onChange]);

  return (
    <div className="form-container">
      <div className="form-group">
        <label htmlFor="method">Notification Method</label>
        <select 
          id="method" 
          value={method} 
          onChange={e => setMethod(e.target.value)}
          className="form-control"
        >
          <option value="email">Email</option>
          <option value="sms">SMS</option>
          <option value="webhook">Webhook</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="recipient">
          {method === 'email' ? 'Email Address' : 
           method === 'sms' ? 'Phone Number' : 
           'Webhook URL'}
        </label>
        <input 
          id="recipient" 
          type={method === 'email' ? 'email' : 'text'} 
          value={recipient} 
          onChange={e => setRecipient(e.target.value)}
          className="form-control"
          placeholder={
            method === 'email' ? 'user@example.com' : 
            method === 'sms' ? '+1234567890' : 
            'https://example.com/webhook'
          }
        />
      </div>

      <div className="form-group">
        <label htmlFor="message">Message</label>
        <textarea 
          id="message" 
          value={message} 
          onChange={e => setMessage(e.target.value)}
          className="form-control"
          rows={3}
          placeholder="Your custom notification message"
        />
      </div>

      <div className="form-group checkbox-group">
        <input 
          id="includeBalances" 
          type="checkbox" 
          checked={includeBalances} 
          onChange={e => setIncludeBalances(e.target.checked)}
        />
        <label htmlFor="includeBalances">Include account balances in notification</label>
      </div>
    </div>
  );
};

export default NotificationForm;