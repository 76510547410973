// /root/money-split/src/components/CreateAutomation/ParameterForms/TriggerForms/TimeBasedForm.tsx
import React, { useState, useEffect } from 'react';

interface TimeBasedFormProps {
  onChange: (params: any) => void;
  initialParams?: any;
}

const TimeBasedForm: React.FC<TimeBasedFormProps> = ({ 
  onChange, 
  initialParams = {} 
}) => {
  const [frequency, setFrequency] = useState(initialParams.frequency || 'daily');
  const [time, setTime] = useState(initialParams.time || '12:00');
  const [dayOfWeek, setDayOfWeek] = useState(initialParams.day_of_week || 'monday');
  const [dayOfMonth, setDayOfMonth] = useState(initialParams.day_of_month || 1);
  
  useEffect(() => {
    const params: any = { frequency };
    
    if (frequency === 'daily') {
      params.time = time;
    } else if (frequency === 'weekly') {
      params.time = time;
      params.day_of_week = dayOfWeek;
    } else if (frequency === 'monthly') {
      params.time = time;
      params.day_of_month = parseInt(dayOfMonth);
    }
    
    onChange(params);
  }, [frequency, time, dayOfWeek, dayOfMonth, onChange]);

  return (
    <div className="form-container">
      <div className="form-group">
        <label htmlFor="frequency">Frequency</label>
        <select 
          id="frequency" 
          value={frequency} 
          onChange={e => setFrequency(e.target.value)}
          className="form-control"
        >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="time">Time</label>
        <input 
          id="time" 
          type="time" 
          value={time} 
          onChange={e => setTime(e.target.value)}
          className="form-control"
        />
      </div>

      {frequency === 'weekly' && (
        <div className="form-group">
          <label htmlFor="dayOfWeek">Day of Week</label>
          <select 
            id="dayOfWeek" 
            value={dayOfWeek} 
            onChange={e => setDayOfWeek(e.target.value)}
            className="form-control"
          >
            <option value="monday">Monday</option>
            <option value="tuesday">Tuesday</option>
            <option value="wednesday">Wednesday</option>
            <option value="thursday">Thursday</option>
            <option value="friday">Friday</option>
            <option value="saturday">Saturday</option>
            <option value="sunday">Sunday</option>
          </select>
        </div>
      )}

      {frequency === 'monthly' && (
        <div className="form-group">
          <label htmlFor="dayOfMonth">Day of Month</label>
          <input 
            id="dayOfMonth" 
            type="number" 
            value={dayOfMonth} 
            onChange={e => setDayOfMonth(e.target.value)}
            className="form-control"
            min="1"
            max="31"
          />
        </div>
      )}
    </div>
  );
};

export default TimeBasedForm;