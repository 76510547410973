// src/components/AutomationList/AutomationCard.tsx
import React, { useState } from 'react';

// Update the interface to include the onTrigger prop
interface AutomationCardProps {
  automation: any;
  onDelete: (id: string) => void;
  onToggleStatus: (id: string, newStatus: string) => void;
  onTrigger: (id: string) => Promise<void>; // Added this prop
}

const AutomationCard: React.FC<AutomationCardProps> = ({ 
  automation, 
  onDelete, 
  onToggleStatus,
  onTrigger // Added this prop
}) => {
  // Add state for tracking triggering status
  const [isTriggering, setIsTriggering] = useState(false);
  
  // Handle the trigger with loading state
  const handleTrigger = async () => {
    setIsTriggering(true);
    try {
      await onTrigger(automation.id);
    } finally {
      setIsTriggering(false);
    }
  };

  return (
    <div className="automation-card">
      <div className="card-header">
        <h3>{automation.name}</h3>
        <span className={`status-badge ${automation.status === 'active' ? 'active' : 'inactive'}`}>
          {automation.status === 'active' ? 'Active' : 'Inactive'}
        </span>
      </div>
      
      <div className="card-main">
        <div className="trigger-section">
          <div className="section-label">Trigger</div>
          <div className="trigger-icon">
            <i className="fas fa-bolt"></i>
          </div>
          <div className="trigger-text">
            {automation.trigger.trigger_type}
            {automation.trigger.config && Object.keys(automation.trigger.config).length > 0 && (
              <div style={{ fontSize: '11px', color: 'var(--medium)', marginTop: '4px' }}>
                {Object.entries(automation.trigger.config).map(([key, value]) => (
                  <div key={key}>{key}: {String(value)}</div>
                ))}
              </div>
            )}
          </div>
        </div>
        
        <div className="arrow">→</div>
        
        <div className="action-section">
          <div className="section-label">Action</div>
          <div className="action-icon">
            <i className="fas fa-play"></i>
          </div>
          <div className="action-text">
            {automation.action.action_type}
            {automation.action.config && Object.keys(automation.action.config).length > 0 && (
              <div style={{ fontSize: '11px', color: 'var(--medium)', marginTop: '4px' }}>
                {Object.entries(automation.action.config).map(([key, value]) => (
                  <div key={key}>{key}: {String(value)}</div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      
      {/* Show execution info if available */}
      {automation.last_execution && (
        <div className="execution-info">
          <div>Last executed: {new Date(automation.last_execution.timestamp * 1000).toLocaleString()}</div>
          <div>Status: {automation.last_execution.success ? 'Succeeded' : 'Failed'}</div>
        </div>
      )}
      
      <div className="card-actions">
        <button 
          className={`deactivate-btn ${automation.status === 'active' ? '' : 'activate-btn'}`}
          onClick={() => onToggleStatus(automation.id, automation.status === 'active' ? 'inactive' : 'active')}
        >
          {automation.status === 'active' ? 'Deactivate' : 'Activate'}
        </button>
        
        {/* Add the Run Now button */}
        <button 
          className="btn-secondary"
          onClick={handleTrigger}
          disabled={isTriggering}
        >
          {isTriggering ? 'Running...' : 'Run Now'}
        </button>
        
        <button 
          className="delete-btn"
          onClick={() => onDelete(automation.id)}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default AutomationCard;