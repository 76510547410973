// src/components/AutomationList/AutomationEngineStatus.tsx
import React, { useState, useEffect } from 'react';
import { getAutomationStatus } from '../../services/api';

const AutomationEngineStatus: React.FC = () => {
  const [status, setStatus] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        setLoading(true);
        const result = await getAutomationStatus();
        if (result.success) {
          setStatus(result.data);
        } else {
          setError(result.error || 'Failed to load automation status');
        }
      } catch (err) {
        console.error('Error fetching automation status:', err);
        setError('Failed to load automation status');
      } finally {
        setLoading(false);
      }
    };

    fetchStatus();
    // Refresh status every 30 seconds
    const interval = setInterval(fetchStatus, 30000);
    
    return () => clearInterval(interval);
  }, []);

  if (loading) return <div className="loading-state small">Loading automation engine status...</div>;
  if (error) return <div className="error-state small">{error}</div>;
  if (!status) return null;

  return (
    <div className="status-card">
      <h3>Automation Engine Status</h3>
      <div className="status-grid">
        <div className="status-item">
          <div className="status-label">Engine Status</div>
          <div className={`status-value ${status.engine_status?.running ? 'active' : 'inactive'}`}>
            {status.engine_status?.running ? 'Running' : 'Inactive'}
          </div>
        </div>
        
        <div className="status-item">
          <div className="status-label">Check Interval</div>
          <div className="status-value">{status.engine_status?.check_interval_seconds || 'N/A'} seconds</div>
        </div>
        
        <div className="status-item">
          <div className="status-label">Uptime</div>
          <div className="status-value">
            {status.engine_status?.uptime_seconds ? 
              formatUptime(status.engine_status.uptime_seconds) : 
              'N/A'}
          </div>
        </div>
        
        <div className="status-item">
          <div className="status-label">Total Automations</div>
          <div className="status-value">{status.automation_stats?.total_count || 0}</div>
        </div>
        
        <div className="status-item">
          <div className="status-label">Active Automations</div>
          <div className="status-value">{status.automation_stats?.active_count || 0}</div>
        </div>
      </div>
    </div>
  );
};

// Helper to format seconds as days, hours, minutes
const formatUptime = (seconds: number): string => {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  
  let result = '';
  if (days > 0) result += `${days}d `;
  if (hours > 0 || days > 0) result += `${hours}h `;
  result += `${minutes}m`;
  
  return result;
};

export default AutomationEngineStatus;