// /root/money-split/src/components/CreateAutomation/ParameterForms/TriggerForms/SolPriceThresholdForm.tsx
import React, { useState, useEffect } from 'react';

interface SolPriceThresholdFormProps {
  onChange: (params: any) => void;
  initialParams?: any;
}

const SolPriceThresholdForm: React.FC<SolPriceThresholdFormProps> = ({ 
  onChange, 
  initialParams = {} 
}) => {
  const [comparison, setComparison] = useState(initialParams.comparison || 'greater_than');
  const [threshold, setThreshold] = useState(initialParams.threshold || 100);
  
  useEffect(() => {
    onChange({
      comparison,
      threshold: parseFloat(threshold)
    });
  }, [comparison, threshold, onChange]);

  return (
    <div className="form-container">
      <div className="form-group">
        <label htmlFor="comparison">Comparison</label>
        <select 
          id="comparison" 
          value={comparison} 
          onChange={e => setComparison(e.target.value)}
          className="form-control"
        >
          <option value="greater_than">Greater than</option>
          <option value="less_than">Less than</option>
          <option value="equal_to">Equal to</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="threshold">Price Threshold (USD)</label>
        <input 
          id="threshold" 
          type="number" 
          value={threshold} 
          onChange={e => setThreshold(e.target.value)}
          className="form-control"
          min="0"
          step="0.01"
        />
      </div>
    </div>
  );
};

export default SolPriceThresholdForm;