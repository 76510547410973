// /root/money-split/src/components/CreateAutomation/ParameterForms/ActionForms/DistributeForm.tsx
import React, { useState, useEffect } from 'react';

interface DistributeFormProps {
  onChange: (params: any) => void;
  initialParams?: any;
}

const DistributeForm: React.FC<DistributeFormProps> = ({ 
  onChange, 
  initialParams = {} 
}) => {
  const [distributionMode, setDistributionMode] = useState(initialParams.distribution_mode || 'split_all');
  const [keepAmount, setKeepAmount] = useState(initialParams.keep_amount || 1);
  const [keepUnit, setKeepUnit] = useState(initialParams.keep_unit || 'sol');
  const [recipient1Percentage, setRecipient1Percentage] = useState(initialParams.recipient1_percentage || 50);
  const [recipient2Percentage, setRecipient2Percentage] = useState(100 - recipient1Percentage);

  // Make sure percentages add to 100
  useEffect(() => {
    setRecipient2Percentage(100 - recipient1Percentage);
  }, [recipient1Percentage]);

  useEffect(() => {
    onChange({
      distribution_mode: distributionMode,
      keep_amount: parseFloat(keepAmount),
      keep_unit: keepUnit,
      recipient1_percentage: Number(recipient1Percentage), // Fixed: use Number instead of parseInt
      recipient2_percentage: Number(recipient2Percentage)  // Fixed: use Number instead of parseInt
    });
  }, [distributionMode, keepAmount, keepUnit, recipient1Percentage, recipient2Percentage, onChange]);

  return (
    <div className="form-container">
      <div className="form-group">
        <label htmlFor="distributionMode">Distribution Mode</label>
        <select
          id="distributionMode"
          value={distributionMode}
          onChange={e => setDistributionMode(e.target.value)}
          className="form-control"
        >
          <option value="split_all">Split All Funds</option>
          <option value="keep">Keep Some, Split Rest</option>
        </select>
      </div>

      {distributionMode === 'keep' && (
        <>
          <div className="form-group">
            <label htmlFor="keepAmount">Amount to Keep</label>
            <input
              id="keepAmount"
              type="number"
              value={keepAmount}
              onChange={e => setKeepAmount(e.target.value)}
              className="form-control"
              min="0"
              step="0.01"
            />
          </div>

          <div className="form-group">
            <label htmlFor="keepUnit">Unit</label>
            <select
              id="keepUnit"
              value={keepUnit}
              onChange={e => setKeepUnit(e.target.value)}
              className="form-control"
            >
              <option value="sol">SOL</option>
              <option value="usd">USD</option>
            </select>
          </div>
        </>
      )}

      <div className="form-group">
        <label htmlFor="recipient1Percentage">Recipient 1 Percentage</label>
        <input
          id="recipient1Percentage"
          type="range"
          min="0"
          max="100"
          value={recipient1Percentage}
          onChange={e => setRecipient1Percentage(parseInt(e.target.value))}
          className="form-control"
        />
        <div className="range-value">{recipient1Percentage}%</div>
      </div>

      <div className="form-group">
        <label htmlFor="recipient2Percentage">Recipient 2 Percentage</label>
        <input
          id="recipient2Percentage"
          type="range"
          min="0"
          max="100"
          value={recipient2Percentage}
          disabled
          className="form-control"
        />
        <div className="range-value">{recipient2Percentage}%</div>
      </div>
    </div>
  );
};

export default DistributeForm;